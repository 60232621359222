<template>
    <div class="contact_panel panel">
        <div class="mask panel">
            <span class="title">联系我们</span>

            <div class="container">
                <div class="container_item">
                    <p class="label">商务合作</p>

                    <span>联系人：{{ company.contact.name }}</span>
                    <span>Email: {{ company.contact.email }}</span>
                    <span v-if="company.contact.mobile">手机: {{ company.contact.mobile }}</span>
                </div>

                <div class="container_item" style="margin-left: 110rem">
                    <p class="label">服务时间</p>

                    <span>工作时间：10:00-21:00</span>
                    <span>休息日：12:00-18:00</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import company from '@/config/company';
export default {
    name: "ContactPanel",
    data() {
        return {
            company: company[location.host] || company["default"],
        }
    }
}
</script>

<style scoped lang="less">
    .contact_panel{
        position: relative;
        height: 346rem;
        padding: 0!important;
        background-image: url("../assets/cover_contact.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .mask{
            position: relative;
            width: 100%;
            height: 100%;
            padding: 0!important;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .title{
            color: #FFFFFF!important;
        }
        .title:after{
            background-color: #FFFFFF!important;
        }
        
        .container{
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 57rem;

            .container_item{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 146rem;
                height: 184rem;
                border-radius: 4rem;
                background-color: rgba(255, 255, 255, 0.2);

                .label{
                    position: relative;
                    font-size: 14rem;
                    color: #FFFFFF;
                    margin-bottom: 30rem;
                }
                .label::after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: -5rem;
                    width: 30rem;
                    height: 1rem;
                    background-color: #FFFFFF;
                    transform: translateX(-50%);
                    border-radius: 1rem;
                }

                span{
                    position: relative;
                    font-size: 10rem;
                    color: #FFFFFF;
                    line-height: 2.5;
                }
            }
        }
    }
</style>