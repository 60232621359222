<template>
    <div class="copy_right">
        <p class="slogan">γvwθl σεaUτOv.认识你自己。μηnδεv aryav.凡事勿过度。</p>
        <p class="icp" @click="onCopyRight">◎2022- 2030{{ data.company }}版权所有.{{ data.icp }}</p>
        <p class="icp" v-if="data.address">地址{{ data.address }}</p>
    </div>
</template>

<script>
import company from '@/config/company';
export default {
    name: "CopyRight",
    data() {
        return {
            data: company[location.host] || company["default"],
        }
    },
    methods: {
        onCopyRight() {
            window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
        },
    }
}
</script>

<style lang="less" scoped>
    .copy_right{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        
        .slogan{
            position: relative;
            text-align: center;
            line-height: 74rem;  
            color: rgba(255, 255, 255, 1);
            font-size: 10rem;     
            background-color: rgba(51, 51, 51, 1);     
        }
        .icp{
            position: relative;
            text-align: center;
            line-height: 44rem;
            color: rgba(255, 255, 255, 1);
            font-size: 10rem;     
            background-color: rgba(34, 34, 34, 1);
            cursor: pointer;
        }
    }
</style>