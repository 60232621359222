<template>
  <div id="app">
    <nav-top v-if="!isIntitle"></nav-top>

    <div class="banner_container">
      <img v-if="isIntitle" class="banner" src="./assets/banner_intitle.png" alt="banner"/>
      <img v-else class="banner" src="./assets/banner.png" alt="banner"/>

      <div class="container" v-if="isIntitle">
        <span class="title">名扬天下起名</span>
        <span class="subtitle">立即帮宝宝取名</span>
      </div>

      <div class="button_group">
        <div class="button button_test" @click="onButton">{{ confirmButton }}</div>
        <div class="button button_login" @click="onButton">登录注册</div>
      </div>
    </div>
    
    <router-view/>

    <copy-right></copy-right>
  </div>
</template>

<script>
  import NavTop from '@/components/NavTop';
  import CopyRight from '@/components/CopyRight';
  import utils from '@/utils/index';
  export default {
    name: "App",
    components: {
      NavTop,
      CopyRight,
    },
    computed: {
      isIntitle() {
        return utils.isIntitle();
      },
      confirmButton() {
        return utils.isIntitle() ? '立即取名' : '点击测试'
      }
    },
    methods: {
      onButton() {
        if(this.isIntitle){
          window.location.href = "http://qm.wuhukj.fun/intitle/home?intitleId=BBQM001&sou=bdsh&swid=naming";
        }else{
          window.location.href = "http://xinli001.xyz";
        }
      }
    }
  }
</script>

<style lang="less">
  #app{
    padding-bottom: 168rem;
    .banner_container{
      position: relative;
      .banner{
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        cursor: pointer;
      }

      .container{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(34, 34, 34, .5);
        color: white;

        .title{
          font-size: 50rem;
          font-weight: bold;
          letter-spacing: 15rem;
        }
        .subtitle{
          font-size: 20rem;
          margin-top: 10rem;
          margin-bottom: 50rem;
          letter-spacing: 5rem;
        }
      }

      .button_group{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 32rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .button{
          width: 128rem;
          height: 40rem;
          line-height: 40rem;
          text-align: center;
          border-radius: 8rem;
          border: 1rem solid #ffffff;
          font-size: 15rem;
          user-select: none;
        }
        .button_test{
          color: rgba(31, 33, 80, 1);
          background-color: #ffffff;
        }
        .button_login{
          margin-left: 30rem;
          color: #ffffff;
        }
      }
    }

    .panel{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40rem;

      .title{
        position: relative;
        font-size: 18rem;
        color: rgba(34, 34, 34, 1);
      }
      .title::after{
        content: '';
        position: absolute;
        left: 50%;
        bottom: -22rem;
        width: 28rem;
        height: 2rem;
        background-color: rgba(112, 114, 255, 1);
        transform: translateX(-50%);
        border-radius: 1rem;
      }
    }
  }
</style>
