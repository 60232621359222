<template>
    <div>
        <contact-panel style="margin-top: 40rem"></contact-panel>
    </div>
</template>

<script>
// @ is an alias to /src
import ContactPanel from '@/components/ContactPanel';
export default {
  name: 'ContactView',
  components: {
    ContactPanel,
  }
}
</script>