import { render, staticRenderFns } from "./ContactPanel.vue?vue&type=template&id=6e58c587&scoped=true&"
import script from "./ContactPanel.vue?vue&type=script&lang=js&"
export * from "./ContactPanel.vue?vue&type=script&lang=js&"
import style0 from "./ContactPanel.vue?vue&type=style&index=0&id=6e58c587&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e58c587",
  null
  
)

export default component.exports