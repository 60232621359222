<template>
    <div class="solution_panel panel">
        <span class="title">解决方案</span>

        <div class="container">
            <template v-for="(item, index) in solutionList">
                <div class="container_item" v-bind:key="index">
                    <img class="cover" :src="item.cover" alt="cover"/>
                    <div class="container_item_text">
                        <p class="container_item_title">{{ item.title }}</p>
                        <p class="container_item_desc">{{ item.desc }}</p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SolutionPanel",
        data() {
            return {
                solutionList: [
                    {
                        cover: require("../assets/solution_1.png"),
                        title: "心理测评服务",
                        desc: "内容涵盖性格、情感、职场、健康、人际、亲子、能力等",
                    },
                    {
                        cover: require("../assets/solution_2.png"),
                        // title: "国学测评服务",
                        // desc: "内容涵盖起名、星座等国学测评服务",
                        title: "性格测评服务",
                        desc: "内容涵盖MBTI、赫兰德职业等性格测评服务",
                    },
                    {
                        cover: require("../assets/solution_3.png"),
                        title: "心理咨询服务",
                        desc: "致力于为全国用户提供可信赖的心理咨询服务，为用户提供个人成长，婚姻情感及心理健康等方向的心理咨询",
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="less">
    .solution_panel{
        background-color: rgba(248, 249, 253, 1);

        .container{
            position: relative;
            display: flex;
            margin-top: 57rem;

            .container_item{
                position: relative;
                width: 187rem;
                background-color: #FFFFFF;
                border-radius: 4rem;

                .cover{
                    position: relative;
                    width: 100%;
                    height: 104rem;
                }
                .container_item_text{
                    position: relative;
                    padding: 15rem;

                    .container_item_title{
                        position: relative;
                        font-size: 10rem;
                        font-weight: bold;
                        color: rgba(34, 34, 34, 1);
                        padding-bottom: 10rem;
                        border-bottom: 0.5rem solid rgba(237, 237, 237, 1);
                    }
                    .container_item_desc{
                        position: relative;
                        min-height: 19rem;
                        font-size: 7rem;
                        margin-top: 10rem;
                        color: rgba(165, 165, 165, 1);
                    }
                }
            }
            .container_item:not(:last-of-type){
                margin-right: 20rem;
            }
        }
    }
</style>