<template>
    <div class="test_panel panel">
        <span class="title">测试大厅</span>

        <div class="container">
            <template v-for="(row, ri) in testList">
                <div class="container_row" v-bind:key="ri">
                    <template v-for="(item, index) in row">
                        <div class="container_item" v-bind:key="index">
                            <img class="cover" :src="item.cover"/>
                            <p class="label" :style="`background-image: url(${item.labelBack})`">{{ item.label }}</p>
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <p class="desc">我们立志打造国内最专业的心理测评服务平台。团队由20多位有5~ 10年心理学受训或从业经历的权威心理测评专家组成。七大主题，170+专 业心理测评，近2000趣味测评，内容涵盖性格、情感、职场、健康、人际、亲子、能力。依托国内外专业心理学量表，将测试者的认知、行为、情感的心理活动量化，只需10~30分钟答题，即可帮助测试者360度解读自己的心理状态。</p>
    </div>
</template>

<script>
    export default {
        name: "TestPanel",
        data() {
            return {
                testList: [
                    [
                        {
                            cover: require("../assets/test_1.png"),
                            labelBack: require("../assets/test_linearback_1.png"),
                            label: "性格测试",
                        },
                        {
                            cover: require("../assets/test_2.png"),
                            labelBack: require("../assets/test_linearback_2.png"),
                            label: "智商测试",
                        },
                        {
                            cover: require("../assets/test_3.png"),
                            labelBack: require("../assets/test_linearback_3.png"),
                            label: "职场测试",
                        },
                        {
                            cover: require("../assets/test_4.png"),
                            labelBack: require("../assets/test_linearback_4.png"),
                            label: "健康测试",
                        },
                        {
                            cover: require("../assets/test_5.png"),
                            labelBack: require("../assets/test_linearback_5.png"),
                            label: "人际测试",
                        },
                    ],
                    [
                        {
                            cover: require("../assets/test_6.png"),
                            labelBack: require("../assets/test_linearback_6.png"),
                            label: "亲子测试",
                        },
                        {
                            cover: require("../assets/test_7.png"),
                            labelBack: require("../assets/test_linearback_7.png"),
                            label: "趣味测试",
                        },
                        {
                            cover: require("../assets/test_8.png"),
                            labelBack: require("../assets/test_linearback_8.png"),
                            label: "起名测试",
                        },
                        {
                            cover: require("../assets/test_9.png"),
                            labelBack: require("../assets/test_linearback_9.png"),
                            label: "星座测试",
                        },
                        {
                            cover: require("../assets/test_10.png"),
                            labelBack: require("../assets/test_linearback_10.png"),
                            label: "敬请期待",
                        },
                    ]
                ]
            }
        }
    }
</script>

<style lang="less" scoped>
    .test_panel{
        background-color: #FFFFFF;

        .container{
            position: relative;
            margin-top: 57rem;

            .container_row{
                position: relative;
                display: flex;
                margin-bottom: 10rem;

                .container_item{
                    position: relative;
                    width: 112rem;
                    height: 75rem;
                    border-radius: 4rem;

                    .cover{
                        position: relative;
                        width: 100%;
                        height: 100%;
                    }
                    .label{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 26rem;
                        // background-image: url("../assets/test_linearback_1.png");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        font-size: 10rem;
                        color: rgba(34, 34, 34, 1);
                    }
                }
                .container_item:not(:last-of-type){
                    margin-right: 10rem;
                }                
            }
        }

        .desc{
            position: relative;
            margin-top: 10rem;
            max-width: 585rem;
            color: rgba(102, 102, 102, 1);
            font-size: 7rem;
        }
    }
</style>