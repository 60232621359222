<template>
    <div>
        <solution-panel></solution-panel>
    </div>
</template>

<script>
// @ is an alias to /src
import SolutionPanel from '@/components/SolutionPanel';
export default {
  name: 'SolutionView',
  components: {
    SolutionPanel,
  }
}
</script>