import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import IntroView from '../views/intro.vue'
import SolutionView from '../views/solution.vue'
import TestView from '../views/test.vue'
import ContactView from '../views/contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/intro',
    name: 'IntroView',
    component: IntroView
  },
  {
    path: '/solution',
    name: 'SolutionView',
    component: SolutionView
  },
  {
    path: '/test',
    name: 'TestView',
    component: TestView
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
