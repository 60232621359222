<template>
    <div>
        <test-panel></test-panel>
    </div>
</template>

<script>
// @ is an alias to /src
import TestPanel from '@/components/TestPanel';
export default {
  name: 'TestView',
  components: {
    TestPanel,
  }
}
</script>