<template>
  <div>
    <intro-panel></intro-panel>
  </div>
</template>

<script>
// @ is an alias to /src
import IntroPanel from '@/components/IntroPanel';
export default {
  name: 'IntroView',
  components: {
    IntroPanel,
  }
}
</script>