<template>
    <div class="nav">
        <div class="logo">
            <!-- <img src="../assets/logo.png" alt="logo"/> -->
            <span>{{ company.title }}</span>
        </div>

        <template v-for="(item, index) in navList">
            <router-link active-class="nav_item_active" v-bind:key="index" :to="item.path" class="nav_item">
                <div class="label">{{item.label}}</div>
            </router-link>
        </template>
    </div>
</template>

<script>
import company from '@/config/company';
export default {
    name: "NavTop",
    data() {
        return {
            company: company[location.host] || company["default"],
            navList: [
                {
                    path: "/home",
                    label: "网站首页",
                },
                {
                    path: "/intro",
                    label: "公司介绍",
                },
                {
                    path: "/solution",
                    label: "解决方案",
                },
                {
                    path: "/test",
                    label: "测试大厅",
                },
                {
                    path: "/contact",
                    label: "联系我们",
                }
            ]
        }
    },
}
</script>

<style lang="less" scoped>
    .nav{
        position: fixed;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 40rem;
        min-width: 960px;
        padding-bottom: 7rem;
        background-color: rgba(255, 255, 255, 0.2);

        .logo{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 50%;
            left: 22%;
            transform: translateX(-50%) translateY(-10.5rem);
            font-size: 8rem;
            color: #FFFFFF;

            img{
                position: relative;
                width: 20rem;
                height: auto;
                margin-right: 5rem;
            }
        }

        .nav_item{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 7.5rem;
            height: 40rem;
            color: rgba(255, 255, 255, 0.6);
            transition: all .3s linear;

            .label{
                position: relative;
                font-size: 8rem;
            }
            .label::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: -11rem;
                width: 10rem;
                height: 2rem;
                background-color: #ffffff;
                transform: translateX(-50%);
                border-radius: 1rem;
                opacity: 0;
                transition: all .3s linear;
            }
        }
        .nav_item_active{
            color: rgba(255, 255, 255, 1);

            .label::after{
                opacity: 1;                
            }
        }
    }
</style>