<template>
    <div class="intro_panel panel">
        <span class="title">公司介绍</span>

        <div class="container">
            <div class="container_text">
                <div class="company_title">
                    <p>{{ company.company }}</p>
                    <p class="company_subtitle">{{ company.company_en }}</p>
                </div>

                <p class="company_desc">{{ company.company }}由一批年轻、富有活力的互联网资深人士组成，是-家专业的互联网心理服务提供平台。我们的理念是“更好的认识你自己”,旨在为更多人的成长提供专业指导。其中，包括心理测评平台、企业人才测评系统等，满足个人、企业、组织等心理测评服务需求。我们立志打造国内最专业的心理测评服务平台，通过互联网科技的力量，内容涵盖性格、情感、职场、健康、人际、亲子、能力等;依托国内外专业心理学量表，将测试者的认知、行为、情感的心理活动量化，只需10~30分钟答题， 即可帮助测试者360度解读自己的心理状态。</p>
            </div>
            <img class="container_banner" src="../assets/cover_intro.png" alt="cover_intro"/>
        </div>
    </div>
</template>

<script>
import company from '@/config/company';
export default {
    name: "IntroPanel",
    data() {
        return {
            company: company[location.host] || company["default"],
        }
    }
}
</script>

<style lang="less" scoped>
    .intro_panel{
        background-color: #FFFFFF;

        .container{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 57rem;

            .container_text{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 296rem;
                min-height: 160rem;

                .company_title{
                    position: relative;
                    font-size: 15rem;
                    color: rgba(34, 34, 34, 1);
                    
                    .company_subtitle{
                        margin-top: 6rem;
                        color: rgba(165, 165, 165, 1);
                        font-size: 10rem;
                    }
                }
                .company_desc{
                    position: relative;
                    width: 100%;
                    font-size: 7rem;
                    color: rgba(102, 102, 102, 1);
                }
            }
            
            .container_banner{
                position: relative;
                width: 243rem;
                height: 160rem;
                margin-left: 60rem;
            }
        }
    }
</style>