<template>
  <div class="home">
    <intro-panel></intro-panel>

    <template v-if="!isIntitle">
      <solution-panel></solution-panel>
      <test-panel></test-panel>
    </template>

    <contact-panel></contact-panel>
  </div>
</template>

<script>
// @ is an alias to /src
import IntroPanel from '@/components/IntroPanel';
import SolutionPanel from '@/components/SolutionPanel';
import TestPanel from '@/components/TestPanel';
import ContactPanel from '@/components/ContactPanel';

import utils from '@/utils/index';

export default {
  name: 'HomeView',
  components: {
    IntroPanel,
    SolutionPanel,
    TestPanel,
    ContactPanel,
  },
  computed: {
    isIntitle() {
      return utils.isIntitle();
    }
  }
}
</script>

<style lang="less" scoped>
  
</style>